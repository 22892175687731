import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FormControl, MenuItem, useTheme, } from '@mui/material';
import { useEffect, useMemo, useState, } from 'react';
import deepmerge from 'deepmerge';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PortfolioCard, PortfolioContainer, PortfolioReferralLinkDisplay, } from '@entities/portfolio';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import { ReactComponent as CopyIcon } from '@icons/wolfkit-light/square-on-square-light.svg';
import { ReactComponent as DeleteIcon } from '@icons/wolfkit-light/trash-light.svg';
import { ReactComponent as DisabledIcon } from '@icons/wolfkit-light/lock-light.svg';
import { useAppSelector } from '@app/store/Hooks';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { ContainerColumn, ContainerRow, alignToCenter } from '@components/styled';
import { Body, BodySemiBold } from '@components/styled/Typography';
import Select from '@shared/ui/inputs/Select';
import IconButton from '@shared/ui/buttons/Button/IconButton';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import SegmentButtons from '@shared/ui/buttons/SegmentButtons';
import Icon from '@shared/ui/icons/Icon';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import { useSavePortfolioMutation } from '@shared/api/portfolio';
import { IsDefined } from '@utils/js-ts';
import WalletSelectItem from './WalletSelectItem';
import validationSchema from './validation';
const PortfolioContainerStyled = styled(PortfolioContainer)(() => ({
    justifyContent: 'space-between',
    alignItems: 'start',
}));
const Form = styled.form((props) => ({
    display: 'flex',
    width: 'auto',
    height: '100%',
    alignItems: 'start',
    justifyContent: 'start',
    gap: props.theme.spacing_sizes.l,
}));
const FieldsRow = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    justifyContent: 'space-between',
}));
const ActionsContainer = styled(FieldsRow)(() => ({
    gap: '10px',
}));
const ReferralContainer = styled(ContainerRow)(() => ({
    width: '100%',
    height: 'auto',
    gap: '10px',
}));
const FieldContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const FormColumn = styled(ContainerColumn)((props) => ({
    width: '100%',
    maxWidth: '400px',
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    paddingTop: props.theme.spacing_sizes.m,
}));
const FormColumnBlock = styled(ContainerColumn)(() => ({
    width: '100%',
    height: 'auto',
    gap: '20px',
}));
const FieldLabelContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const FieldLabel = styled(BodySemiBold)(() => ({}));
const ProgressContainer = styled(alignToCenter(ContainerColumn))(() => ({
    height: '406px',
}));
const BodyWithTextEllipsis = withTextEllipsis(Body);
const Delimitter = () => {
    const theme = useTheme();
    return (_jsx("svg", { width: '400', height: '2', viewBox: '0 0 400 2', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: _jsx("path", { d: 'M0 0.666016H400', stroke: theme.customColors.button.pill.border }) }));
};
const PortfolioReferralLinkDisplayStyled = styled(PortfolioReferralLinkDisplay)(() => ({
    maxWidth: '350px', // button width + gap
}));
const MonthlyFeeIncDecStep = 5;
const PortfolioEdit = ({ portfolio, onEditClose }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { control, handleSubmit, getValues, } = useForm({
        defaultValues: portfolio,
        resolver: yupResolver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        validationSchema(t)),
    });
    const { auth } = useAppSelector(state => state.auth);
    const { data, isFetching } = useGetExchangeAccountsQuery();
    const [savePortfolio, savePortfolioState] = useSavePortfolioMutation();
    const [visibility, setVisibility] = useState(portfolio.visibility);
    const [minimumBalance, setMinimumBalance] = useState(portfolio.minimum_balance_type);
    // TODO: exchange.id in data for some reason is string, check it
    const parentExchange = useMemo(() => data === null || data === void 0 ? void 0 : data.find(exchange => exchange.id.toString() === portfolio.parent_exchange_id.toString()), [data, portfolio]);
    const preview = useMemo(() => deepmerge.all([portfolio, getValues(), { exchange: parentExchange }]), [
        portfolio,
        parentExchange,
        getValues,
    ]);
    const onVisibilityChange = (value) => {
        setVisibility(value);
    };
    const onBalanceChange = (value) => {
        setMinimumBalance(value);
    };
    const referralLinkCopy = () => {
        navigator.clipboard.writeText('https://www.endrex.net/referral?r=xlfdadASwad');
    };
    const cancel = () => {
        onEditClose();
    };
    const save = () => {
        savePortfolio({
            portfolioId: portfolio.id,
            body: Object.assign(Object.assign({}, getValues()), { visibility: 'public' }),
        });
    };
    useEffect(() => {
        if (savePortfolioState.isSuccess) {
            onEditClose();
            savePortfolioState.reset();
        }
    }, [savePortfolioState, onEditClose]);
    if (isFetching || savePortfolioState.isLoading) {
        return (_jsx(ProgressContainer, { children: _jsx(CircularProgress, {}) }));
    }
    if (!IsDefined(portfolio) || !IsDefined(data) || savePortfolioState.isSuccess) {
        return null;
    }
    return (_jsxs(PortfolioContainerStyled, { variant: 'plain', portfolio: portfolio, viewType: 'edit', displayState: 'default', alwaysDisplayOverlay: true, children: [_jsx(PortfolioCard, { portfolio: preview, variant: 'plain', viewType: 'card', disabled: true }), _jsxs(Form, { onSubmit: handleSubmit(save), children: [_jsxs(FormColumn, { children: [_jsxs(FormColumnBlock, { children: [_jsxs(FieldContainer, { children: [_jsx(FieldLabel, { children: t('portfolio.parent_exchange') }), _jsx(FormControl, { children: _jsx(Controller, { name: 'parent_exchange_id', control: control, render: ({ field }) => (_jsx(Select, { value: field.value, onChange: field.onChange, highlightSelected: true, children: data.map(exchange => (_jsx(MenuItem, { value: exchange.id, children: _jsx(WalletSelectItem, { exchange: exchange }) }, exchange.id))) })) }) })] }), _jsx(FieldContainer, { children: _jsx(Controller, { name: 'name', control: control, render: ({ field, fieldState }) => {
                                                var _a, _b;
                                                return (_jsx(Input, { ref: field.ref, label: t('portfolio.portfolio_name'), value: field.value, onChange: field.onChange, onBlur: field.onChange, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: false, maxCount: 30, filled: true }));
                                            } }) })] }), _jsxs(FormColumnBlock, { children: [_jsx(Delimitter, {}), _jsxs(FieldsRow, { children: [_jsxs(FieldContainer, { children: [_jsx(FieldLabel, { children: t('portfolio.monthly_subscription_fee') }), _jsx(Controller, { name: 'monthly_following_fee', control: control, render: ({ field }) => (_jsx(QuantityInput, { incDecStep: MonthlyFeeIncDecStep, value: field.value, onChange: field.onChange })) })] }), _jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { children: [_jsx(FieldLabel, { children: t('portfolio.portfolio_visibility') }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: DisabledIcon }) })] }), _jsx(SegmentButtons, { segments: [
                                                            {
                                                                key: 'public',
                                                                displayValue: t('portfolio.visibility.public'),
                                                            },
                                                            {
                                                                key: 'private',
                                                                displayValue: t('portfolio.visibility.private'),
                                                            },
                                                        ], currentSegmentKey: visibility, onSelect: onVisibilityChange, disabled: true })] })] })] })] }), _jsxs(FormColumn, { children: [_jsxs(FormColumnBlock, { children: [_jsxs(FieldContainer, { children: [_jsx(FieldLabel, { children: t('portfolio.share_link') }), _jsxs(ReferralContainer, { children: [_jsx(PortfolioReferralLinkDisplayStyled, { link: 'https://www.endrex.net/referral?r=xlfdadASwadxlfdadASwadxlfdadASwad' }), _jsx(IconButton, { variant: 'filled', color: 'primary', IconComponent: CopyIcon, size: 'medium', onClick: referralLinkCopy })] })] }), _jsxs(FieldsRow, { children: [_jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { children: [_jsx(FieldLabel, { children: t('portfolio.recommended_stop_loss') }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: DisabledIcon }) })] }), _jsx(QuantityInput, { value: 35, variant: 'percent', incDecStep: MonthlyFeeIncDecStep, onChange: () => { }, fullWidth: true, disabled: true })] }), _jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { children: [_jsx(FieldLabel, { children: t('portfolio.minimum_balance') }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: DisabledIcon }) }), _jsx(QuestionMarkTooltip, { title: t('portfolio.minimum_balance'), placement: 'top-start', iconColor: theme.customColors.input.borderHover, arrow: true })] }), _jsx(SegmentButtons, { segments: [
                                                            {
                                                                key: '1_to_1',
                                                                displayValue: '1:1',
                                                            },
                                                            {
                                                                key: 'third_part',
                                                                displayValue: '1:3',
                                                            },
                                                            {
                                                                key: 'fifth_part',
                                                                displayValue: '1:5',
                                                            },
                                                        ], currentSegmentKey: minimumBalance, onSelect: onBalanceChange, disabled: true })] })] })] }), _jsxs(ActionsContainer, { children: [_jsx(Button, { variant: 'filled', color: 'primary', type: 'submit', style: {
                                            flex: 1,
                                        }, children: _jsx(BodySemiBold, { children: t('actions.save', { ns: 'common' }) }) }), _jsx(Button, { variant: 'tinted', color: 'primary', onClick: cancel, style: {
                                            flex: 1,
                                        }, children: _jsx(BodySemiBold, { children: t('actions.cancel', { ns: 'common' }) }) }), _jsx(Button, { variant: 'filled', color: 'error', startIcon: DeleteIcon, style: {
                                            flex: 1.5,
                                        }, children: _jsx(BodyWithTextEllipsis, { tooltipText: t('portfolio.actions.close'), children: t('portfolio.actions.close') }) })] })] })] })] }));
};
export default PortfolioEdit;
